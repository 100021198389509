import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import DefaultLayout from '/components/layouts/DefaultLayout'
import PageTitle from '/components/common/PageTitle'
import {
  VisbilityContainer,
  HeaderPose,
  CommonPose
} from '/components/home/Animations'
import { Link as ChaliceLink } from '/components/theme/button/Button'
import { PAGEURL } from '/constants'
import { pageRender } from '/components/theme/default'
import { MainCta } from '/components/theme/default/common'
import { withPageCustom } from '/components/utils'

const Exchange = ({ siteData }) => {
  return (
    <div className="intro-block">
      <div className="content">
        <VisbilityContainer>
          <div className="content-wrapper description">
            <HeaderPose
              dangerouslySetInnerHTML={{ __html: siteData.home_title }}
            />
            <CommonPose
              className="secondary-header"
              dangerouslySetInnerHTML={{ __html: siteData.home_description }}
            />
            <CommonPose className="action-row">
              <MainCta />
            </CommonPose>
          </div>
        </VisbilityContainer>
      </div>
    </div>
  )
}

Exchange.propTypes = {
  siteData: PropTypes.object
}

const CalendarBlock = ({ calendarLink }) => (
  <div className="appointment-block">
    <div className="content">
      <VisbilityContainer>
        <CommonPose>
          <h2>Questions? Comments?</h2>
        </CommonPose>
        <div className="description">
          <CommonPose className="description">
            Setup an appointment with one of our customer service
            representatives and put your mind at ease.
          </CommonPose>
          <CommonPose className="action-row">
            <ChaliceLink
              target="_blank"
              content="nofollow"
              rel="noopener noreferrer"
              href={calendarLink}
            >
              Schedule your Appointment
            </ChaliceLink>
          </CommonPose>
        </div>
      </VisbilityContainer>
    </div>
  </div>
)

CalendarBlock.propTypes = {
  calendarLink: PropTypes.string
}

const ChaliceHome = inject('store')(
  observer(({ siteData, page }) => {
    if (page) {
      return (
        <div>
          <PageTitle
            title={`${page.title || siteData.home_title || ''}`}
            description={`${page.meta_description || ''}`}
          />
          <div
            className="home-wrapper component-library"
            style={{ paddingTop: '80px' }}
          >
            {pageRender(page)}
          </div>
        </div>
      )
    } else {
      // Boring default homepage page
      return (
        <div>
          <PageTitle title={`${siteData.home_title || ''}`} />
          <div className="home-wrapper">
            <Exchange siteData={siteData} />
            {siteData.home_calendly_link && (
              <CalendarBlock
                key="calendar"
                calendarLink={siteData.home_calendly_link}
              />
            )}
          </div>
        </div>
      )
    }
  })
)

ChaliceHome.propTypes = {
  store: PropTypes.object,
  page: PropTypes.any
}

ChaliceHome.Layout = DefaultLayout

export const getServerSideProps = withPageCustom(
  'home',
  async (ctx, siteData, profile, page) => {
    return {
      props: {
        page
      }
    }
  },
  {
    attribute: 'allow_homepage',
    redirect: PAGEURL.MARKETPLACE
  }
)

export default ChaliceHome
